module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TC3W4VX","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CHANGEME","short_name":"CHANGEME","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/codebuild/output/src3555207455/src/phx-alacta-do/packages/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d19a767ad4a8212013e5ec570838c152"},
    },{
      plugin: require('../../gatsby-debug-log/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-husky/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{}},
    }]
